.slide-img{
  /* background-size: cover;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh; */
    width: 1162px;

}

.mobile-tab-screen{
  display: inline-grid;
}